<template>
    <div class="mine-client-page">
        <GeekQooSearch></GeekQooSearch>
        <div class="top-title">
            <p>我的客户</p>
        </div>
        <div class="client-list">
            <van-list
                v-model="client.loading"
                :finished="client.finished"
                finished-text="没有更多了"
                @load="getClient()"
            >
                <div v-for="(item,index) in client.list" class="list-item">
                    <div class="item-left">
                        <p>
                            <van-icon name="friends-o"/>
                            <span>{{ item.name }}</span>
                        </p>
                        <p>
                            <van-icon name="wap-home-o"/>
                            <span>{{ item.address }}</span>
                        </p>
                    </div>
                    <a class="item-right" @click="delClient(item)">删除该客户</a>
                </div>
            </van-list>
        </div>
        <a class="add-button" @click="clientInput.show=true">
            <van-icon name="add"/>
            <span>新增一个</span>
        </a>
        <van-dialog v-model="clientInput.show" :show-confirm-button="false" close-on-click-overlay
                    get-container=".shop-cart-page">
            <div class="customer-dialog">
                <van-field
                    v-model="clientInput.customer"
                    left-icon="friends-o"
                    placeholder="请输入客户姓名"
                />
                <van-field
                    v-model="clientInput.address"
                    left-icon="wap-home-o"
                    placeholder="请输入客户楼盘号"
                />
                <van-button block color="#F54A4A" size="small" type="primary" @click="addClient()">新增</van-button>
            </div>
        </van-dialog>
    </div>
</template>

<script>
// 引入mixins
import mixins_config from "@/mixins/config.js";
// 引入组件
import GeekQooSearch from "@/components/GeekQooSearch"
// 引入接口
import {ADD_CUSTOMERS, DEL_CUSTOMERS, GET_CUSTOMERS} from "@/api/dataProvider";

export default {
    name: "MineClient",
    mixins: [mixins_config],
    components: {GeekQooSearch},
    data() {
        return {
            client: {
                current: 1,
                loading: false,
                finished: false,
                list: []
            },
            clientInput: {
                show: false,
                customer: "",
                address: ""
            }
        };
    },
    created() {
    },
    mounted() {
    },
    methods: {
        getClient() {
            let that = this
            GET_CUSTOMERS(
                {
                    page: that.client.current
                },
                function (res) {
                    if (res.data.code === 0) {
                        that.client.loading = false
                        if (that.client.list.length > 0 && res.data.data.current_page == 1) {
                            return false
                        }
                        that.client.list = that.client.list.concat(res.data.data.data)
                        // 判断总页数是否大于当前页
                        if (res.data.data.last_page > that.client.current) {
                            that.client.current += 1;
                        } else {
                            that.client.finished = true;
                        }
                    }
                })
        },
        delClient(value) {
            let that = this
            that.$dialog.confirm({
                title: "提示",
                message: "确定要删除该用户吗？",
            }).then(() => {
                DEL_CUSTOMERS(
                    {
                        customers: value.id
                    },
                    function (res) {
                        if (res.data.code == 0) {
                            location.reload()
                        } else {
                            that.$notify({type: "danger", message: "删除失败，请重试！"});
                        }
                    }
                )

            }).catch(() => {
            });
        },
        addClient() {
            let that = this
            ADD_CUSTOMERS(
                {
                    name: that.clientInput.customer,
                    address: that.clientInput.address,
                },
                function (res) {
                    if (res.data.code == 0) {
                        that.clientInput.show = false
                        location.reload()
                    } else {
                        that.$notify({type: "danger", message: "新增失败，请重试！"});
                    }
                }
            )
        },
    }
}
</script>

<style lang="scss">
.mine-client-page {
    padding: 0 0 50px 0;

    .top-title {
        background: #F96719;

        p {
            line-height: 35px;
            text-align: center;
            font-size: 10px;
            color: #fff;
        }
    }

    .client-list {
        padding: 10px 20px;

        .list-item {
            display: flex;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid #EEEEEE;

            .item-left {
                width: 250px;

                p {
                    &:first-child {
                        margin-bottom: 5px;
                    }

                    i {
                        vertical-align: middle;
                        font-size: 14px;
                        color: #F28246;
                    }

                    span {
                        vertical-align: middle;
                        margin-left: 10px;
                        font-size: 10px;
                        color: #2D2D2D;
                    }
                }
            }

            .item-right {
                display: block;
                margin-left: auto;
                font-size: 10px;
                color: #F54A4A;
            }
        }
    }

    .add-button {
        display: block;
        margin-top: 20px;
        margin-bottom: 50px;
        text-align: center;

        i {
            vertical-align: middle;
            font-size: 14px;
            color: #F28246;
        }

        span {
            margin-left: 10px;
            vertical-align: middle;
            font-size: 10px;
            color: #F28246;
        }
    }

    .customer-dialog {
        padding: 5px 0;

        .van-field {
            .van-field__value {
                font-size: 10px;
            }
        }

        .van-button {
            width: calc(100% - 32px);
            margin: 10px 16px
        }
    }
}
</style>